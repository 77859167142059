import React from "react";

const WebsitesPortfolio = () => {
  const websites = [
    { name: "Digital Marketing", image: "/images/isweb.png" },
    { name: "SolCont", image: "/images/solcontweb.png" },
    { name: "Chic Nail Art", image: "/images/chicweb.png" },
    { name: "BR Agro", image: "/images/bragroweb.png" },
    { name: "Catálogo PDF a FlipBook", image: "/images/catalogoweb.png" },
    { name: "Sistema de Reserva", image: "/images/fondueweb.png" }
  ];

  return (
    <section className="websites-section">
      <div className="websites-container">
        <h2>Desarrollo de Websites</h2>
        <p>Explora algunos de los sitios web que hemos desarrollado para nuestros clientes.</p>
        <div className="websites-grid">
          {websites.map((site, index) => (
            <a key={index} href={site.url} target="_blank" rel="noopener noreferrer" className="website-item">
              <img src={site.image} alt={site.name} />
              <h3>{site.name}</h3>
            </a>
          ))}
        </div>
      </div>

      <style jsx>{`
        .websites-section {
          background: #004d66;
          padding: 100px 20px;
          text-align: center;
          color: #fff;
        }

        .websites-container {
          max-width: 1200px;
          margin: auto;
        }

        h2 {
          font-size: 2.5rem;
          color: light
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          font-size: 1.2rem;
          margin-bottom: 40px;
        }

        .websites-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 20px;
        }

        .website-item {
          text-decoration: none;
          background: #fff;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
          color: var(--primary-color);
        }

        .website-item:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
        }

        .website-item img {
          width: 100%;
          border-radius: 10px;
        }

        h3 {
          font-size: 1.4rem;
          margin-top: 15px;
        }
      `}</style>
    </section>
  );
};

export default WebsitesPortfolio;