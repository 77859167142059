import React from "react";
import { FaGlobe, FaVideo, FaRobot, FaDatabase } from "react-icons/fa";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const projects = [
    { title: "Desarrollo de Websites", icon: <FaGlobe />, link: "/portfolio/websites" },
    { title: "Videos para Redes Sociales", icon: <FaVideo />, link: "/portfolio/videos" },
    { title: "Chatbots con IA", icon: <FaRobot />, link: "/portfolio/chatbots" }
  ];

  return (
    <section className="portfolio-section">
      <div className="portfolio-container">
        <h2>Nuestro Portafolio</h2>
        <p>Explora nuestros servicios y descubre cómo podemos ayudar a tu negocio a destacar.</p>
        <div className="portfolio-grid">
          {projects.map((project, index) => (
            <Link key={index} to={project.link} className="portfolio-item">
              <div className="portfolio-icon">{project.icon}</div>
              <h3>{project.title}</h3>
            </Link>
          ))}
          <a href="https://leadex-isasoft-bragro.netlify.app/login" target="_blank" rel="noopener noreferrer" className="portfolio-item">
            <div className="portfolio-icon"><FaDatabase /></div>
            <h3>Leadex CRM</h3>
          </a>
        </div>
      </div>

      <style jsx>{`
        .portfolio-section {
          background: var(--primary-color);
          padding: 100px 20px;
          text-align: center;
        }

        .portfolio-container {
          max-width: 1200px;
          margin: auto;
        }

        h2 {
          font-size: 2.5rem;
          font-weight: 700;
          color: #fff;
          margin-bottom: 20px;
        }

        p {
          font-size: 1.2rem;
          color: #fff;
          margin-bottom: 40px;
        }

        .portfolio-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 20px;
        }

        .portfolio-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          background: #fff;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          color: var(--primary-color);
        }

        .portfolio-item:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
        }

        .portfolio-icon {
          font-size: 3rem;
          margin-bottom: 15px;
          color: var(--secondary-color);
        }

        h3 {
          font-size: 1.4rem;
          color: var(--primary-color);
        }
      `}</style>
    </section>
  );
};

export default Portfolio;