import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SuccessCases = () => {
  const brands = [
    { name: "Empresa 11", logo: "/images/11.png" },
    { name: "Empresa 1", logo: "/images/1.png" },
    { name: "Empresa 2", logo: "/images/2.png" },
    { name: "Empresa 3", logo: "/images/3.png" },
    { name: "Empresa 4", logo: "/images/4.png" },
    { name: "Empresa 5", logo: "/images/5.png" },
    { name: "Empresa 6", logo: "/images/6.png" },
    { name: "Empresa 7", logo: "/images/7.png" },
    { name: "Empresa 8", logo: "/images/8.png" },
    { name: "Empresa 9", logo: "/images/9.png" },
    { name: "Empresa 10", logo: "/images/10.png" },

  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <section className="success-cases-section">
      <h2>Casos de Éxito</h2>
      <p>Estas son algunas de las empresas que han confiado en nosotros.</p>
      <Slider {...settings} className="brand-slider">
        {brands.map((brand, index) => (
          <div key={index} className="brand-slide">
            <img src={brand.logo} alt={brand.name} />
          </div>
        ))}
      </Slider>
      <style jsx>{`
        .success-cases-section {
          background: #ffffff;
          padding: 80px 20px;
          text-align: center;
        }

        h2 {
          font-size: 2.8rem;
          font-weight: 700;
          color: var(--primary-color);
          margin-bottom: 20px;
        }

        p {
          font-size: 1.2rem;
          margin-bottom: 40px;
          color: var(--text-color);
        }

        .brand-slider {
          max-width: 1200px;
          margin: auto;
        }

        .brand-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
        }

        .brand-slide img {
          max-width: 150px;
          height: auto;
          filter: grayscale(100%);
          transition: filter 0.3s ease-in-out;
        }

        .brand-slide img:hover {
          filter: grayscale(0%);
        }
      `}</style>
    </section>
  );
};

export default SuccessCases;