import React from "react";
import HeroSection from "../components/HeroSection";
import ServicesSection from "../components/ServicesSection";
import WhyChooseUs from "../components/WhyUs";
import SuccessCases from "../components/SuccessCases";

const HomePage = () => {
    return (
        <div className="main-page">
            <HeroSection />
            <SuccessCases/>
            <ServicesSection />
            <WhyChooseUs/>
        </div>
    );
};

export default HomePage;