// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./screens/HomePage";
import Footer from "./components/Footer";
import AboutUs from "./screens/AboutUs";
import ServicePage from "./screens/ServicePage";
import Portfolio from "./screens/Portfolio";
import WebsitesPortfolio from "./screens/WebsitesScreen";
import ChatbotsImportance from "./screens/ChatBot";
import Blog from "./screens/BlogScreen";
import WhatsAppButton from "./components/WAButton";
import TermsAndConditions from "./screens/Terms";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
            <ScrollToTop />
      {/* NavBar */}
      <NavBar />

      {/* Rutas */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/websites" element={<WebsitesPortfolio />} />
        <Route path="/portfolio/chatbots" element={<ChatbotsImportance />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/terminos-de-servicio" element={<TermsAndConditions />} />
      </Routes>
      <Footer/>
      <WhatsAppButton />
    </Router>
  );
}

export default App;