import react from "react";
import ServicesSection from "../components/ServicesSection";

const ServicesPage = () => {
    return (
        <div className="main-page">
            <ServicesSection />
        </div>
    );
};

export default ServicesPage;