import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t } = useTranslation();
  const [visibleIndex, setVisibleIndex] = useState(0);
  const services = t("services_list", { returnObjects: true }) || [];

  useEffect(() => {
    if (!Array.isArray(services) || services.length === 0) return;
    const intervalId = setInterval(() => {
      setVisibleIndex((prevIndex) => (prevIndex + 1) % services.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [services]);

  const handleButtonClick = () => {
    const phoneNumber = "522381013593"; // ← Cambia esto por el número de WhatsApp, sin signos ni espacios
    const message = encodeURIComponent(t("cta_alert")); // Traducción desde i18n
    const url = `https://wa.me/${phoneNumber}?text=${message}`;
  
    window.open(url, "_blank");
  };

  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>
          {t("title")} <span>{t("highlight")}</span>
        </h1>
        {Array.isArray(services) && services.length > 0 && (
          <h3 className="service-container">
            {services.map((service, index) => (
              <span
                key={index}
                className={`service-text ${index === visibleIndex ? "visible" : "hidden"}`}
              >
                {service}
              </span>
            ))}
          </h3>
        )}
        <p>{t("description")}</p>
        <button onClick={handleButtonClick}>{t("cta_button")}</button>
      </div>

      <style jsx>{`
        /* 🔹 Sección Hero */
        .hero-section {
          position: relative;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          background: linear-gradient(135deg, #1a3a5a, #004d66, #005f73, #007f8c);
          background-size: 400% 400%;
          animation: gradientAnimation 15s ease infinite;
        }

        @keyframes gradientAnimation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        /* 🔹 Estrellas dinámicas */
        .hero-section::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 1%, transparent 20%);
          opacity: 0.5;
          animation: twinkling 4s infinite ease-in-out alternate;
        }

        @keyframes twinkling {
          0% {
            opacity: 0.3;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 0.3;
          }
        }

        /* 🔹 Contenido del Hero */
        .hero-content {
          text-align: center;
          color: var(--text-light);
          z-index: 1;
          max-width: 800px;
          padding: 0 20px;
        }

        .hero-content h1 {
          font-size: 3.5rem;
          font-weight: 700;
          margin-bottom: 20px;
          color: var(--text-light);
          font-family: var(--font-title);
        }

        .hero-content h1 span {
          color: var(--accent-color);
        }

        .hero-content h3 {
          font-size: 1.8rem;
          font-weight: 600;
          margin-bottom: 20px;
          color: var(--text-light);
          font-family: var(--font-title);
        }

        .hero-content p {
          font-size: 1.2rem;
          margin-bottom: 40px;
          color: var(--text-light);
          font-family: var(--font-text);
          line-height: 1.8;
        }

        /* 🔹 Botón interactivo */
        .hero-content button {
          font-size: 1.2rem;
          font-weight: 600;
          padding: 12px 30px;
          border: none;
          border-radius: 5px;
          background-color: var(--primary-color);
          color: var(--text-light);
          cursor: pointer;
          transition: background-color 0.3s ease, transform 0.3s ease;
        }

        .hero-content button:hover {
          background-color: var(--secondary-color);
          transform: translateY(-3px);
        }

        /* 📌 Feedback táctil en móviles */
        @media (hover: none) {
          .hero-content button:active {
            transform: scale(0.95);
          }
        }

        /* 🔹 Animación de texto */
        .service-container {
          position: relative;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }

        .service-text {
          position: absolute;
          opacity: 0;
          transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
          font-size: 1.8rem;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
        }

        .service-text.visible {
          opacity: 1;
          transform: translateY(0);
        }

        .service-text.hidden {
          opacity: 0;
          transform: translateY(10px);
        }

        /* 📌 RESPONSIVIDAD */
        @media (max-width: 1024px) {
          .hero-content h1 {
            font-size: 3rem;
          }
          .service-text {
            font-size: 1.5rem;
          }
          .hero-content p {
            font-size: 1.2rem;
          }
          .hero-content button {
            font-size: 1.1rem;
          }
        }

        @media (max-width: 768px) {
          .hero-content h1 {
            font-size: 2.4rem;
          }
          .service-text {
            font-size: 1.3rem;
          }
          .hero-content p {
            font-size: 1.1rem;
          }
          .hero-content button {
            font-size: 1rem;
            padding: 10px 25px;
          }
        }

        @media (max-width: 480px) {
          .hero-content h1 {
            font-size: 2rem;
          }
          .service-text {
            font-size: 1.2rem;
          }
          .hero-content p {
            font-size: 1rem;
          }
          .hero-content button {
            font-size: 0.9rem;
            padding: 8px 20px;
          }
        }
      `}</style>
    </section>
  );
};

export default HeroSection;
