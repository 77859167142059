import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      home: "Home",
      about: "About Us",
      services: "Services",
      portfolio: "Portfolio",
      blog: "Blog",
      language: "Language",
      title: "Boost your brand to the",
      highlight: "next level",
      services_list: [
        "Digital Marketing Strategy",
        "SEO Optimization",
        "Social Media Management",
        "Query Automation",
        "Software Development",
        "Graphic Design",
        "Content Creation",
        "Web Design",
        "Web Development"
      ],
      description: "We create tailored solutions to optimize your resources and attract new customers, combining digital marketing strategies and technological innovation to drive your business growth.",
      cta_button: "See Process",
      cta_alert: "Discover how we can help you!"
    },
  },
  es: {
    translation: {
      home: "Inicio",
      about: "Nosotros",
      services: "Servicios",
      portfolio: "Portafolio",
      blog: "Blog",
      language: "Idioma",
      title: "Impulsa tu marca al",
      highlight: "siguiente nivel",
      services_list: [
        "Estrategia de Marketing Digital",
        "Optimización de SEO",
        "Gestión de Redes Sociales",
        "Automatización de Consultas",
        "Desarrollo de Software",
        "Diseño Gráfico",
        "Creación de Contenido",
        "Diseño Web",
        "Desarrollo Web"
      ],
      description: "Creamos soluciones personalizadas para optimizar tus recursos y atraer nuevos clientes, combinando estrategias de marketing digital e innovación tecnológica para impulsar el crecimiento de tu negocio.",
      cta_button: "Ver Proceso",
      cta_alert: "¡Descubre cómo podemos ayudarte!"
    },
  },
  fr: {
    translation: {
      home: "Accueil",
      about: "À propos",
      services: "Services",
      portfolio: "Portfolio",
      blog: "Blog",
      language: "Langue",
      title: "Propulsez votre marque au",
      highlight: "niveau suivant",
      services_list: [
        "Stratégie de marketing numérique",
        "Optimisation SEO",
        "Gestion des réseaux sociaux",
        "Automatisation des requêtes",
        "Développement de logiciels",
        "Conception graphique",
        "Création de contenu",
        "Conception web",
        "Développement web"
      ],
      description: "Nous créons des solutions sur mesure pour optimiser vos ressources et attirer de nouveaux clients, en combinant des stratégies de marketing numérique et une innovation technologique pour stimuler la croissance de votre entreprise.",
      cta_button: "Voir le processus",
      cta_alert: "Découvrez comment nous pouvons vous aider!"
    },
  },
  
};

i18n.use(initReactI18next).init({
  resources,
  lng: "es", // Idioma por defecto
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
