import React from "react";
import { FaRobot, FaComments, FaChartLine, FaClock } from "react-icons/fa";

const ChatbotsImportance = () => {
  return (
    <section className="chatbots-section">
      <div className="chatbots-container">
        <h2>Uso de Chatbots en Servicio al Cliente</h2>
        <p>
          Los chatbots han revolucionado la manera en que las empresas interactúan con sus clientes y prospectos.
          Ya sean impulsados por inteligencia artificial o basados en flujos predeterminados, su impacto en las ventas y la
          experiencia del usuario es innegable.
        </p>

        <div className="chatbots-grid">
          <div className="chatbot-item">
            <FaRobot className="chatbot-icon" />
            <h3>Automatización Inteligente</h3>
            <p>
              Los chatbots con IA pueden entender preguntas complejas y proporcionar respuestas personalizadas en tiempo real.
            </p>
          </div>
          <div className="chatbot-item">
            <FaComments className="chatbot-icon" />
            <h3>Disponibilidad 24/7</h3>
            <p>
              Atender clientes en cualquier momento mejora la conversión y la satisfacción, sin importar la zona horaria.
            </p>
          </div>
          <div className="chatbot-item">
            <FaChartLine className="chatbot-icon" />
            <h3>Optimización de Ventas</h3>
            <p>
              Filtra prospectos, responde preguntas frecuentes y guía al usuario en el proceso de compra para cerrar más ventas.
            </p>
          </div>
          <div className="chatbot-item">
            <FaClock className="chatbot-icon" />
            <h3>Ahorro de Tiempo y Costos</h3>
            <p>
              Reduce la carga del equipo de atención al cliente y permite enfocarse en tareas estratégicas.
            </p>
          </div>
        </div>
      </div>

      <style jsx>{`
        .chatbots-section {
          background: var(--primary-color);
          padding: 100px 20px;
          text-align: center;
          color: #fff;
        }

        .chatbots-container {
          max-width: 1200px;
          margin: auto;
        }

        h2 {
         color: #FFF;
          font-size: 2.5rem;
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          font-size: 1.2rem;
          margin-bottom: 40px;
        }

        .chatbots-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 20px;
        }

        .chatbot-item {
          background: #fff;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
          color: var(--primary-color);
        }

        .chatbot-item:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
        }

        .chatbot-icon {
          font-size: 3rem;
          margin-bottom: 15px;
          color: var(--secondary-color);
        }

        h3 {
          font-size: 1.4rem;
          margin-top: 10px;
        }
      `}</style>
    </section>
  );
};

export default ChatbotsImportance;