import React from "react";
import { useTranslation } from "react-i18next";

const WhyChooseUs = () => {
  const { t } = useTranslation();

  const reasons = [
    {
      title: "Experiencia Comprobada",
      description: "Contamos con años de experiencia brindando soluciones efectivas a empresas de todos los sectores.",
      icon: "🏆"
    },
    {
      title: "Soluciones Personalizadas",
      description: "Cada cliente es único, por lo que adaptamos nuestras estrategias a sus necesidades específicas.",
      icon: "🎯"
    },
    {
      title: "Innovación y Tecnología",
      description: "Implementamos las últimas tecnologías para garantizar resultados óptimos en cada proyecto.",
      icon: "🚀"
    },
    {
      title: "Soporte Continuo",
      description: "Acompañamos a nuestros clientes en cada etapa del proceso, asegurando su éxito a largo plazo.",
      icon: "💡"
    }
  ];

  return (
    <section className="why-choose-us-section">
      <div className="why-choose-us-content">
    
        <div className="why-list">
          {reasons.map((reason, index) => (
            <div key={index} className="why-item">
              <span className="why-icon">{reason.icon}</span>
              <div className="why-text">
                <h3>{reason.title}</h3>
                <p>{reason.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        .why-choose-us-section {
          background: linear-gradient(135deg, #f8f9fa, #e3f2fd);
          padding: 80px 20px;
          text-align: left;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .why-choose-us-content {
          max-width: 900px;
        }

        .why-choose-us-content h2 {
          font-size: 2.8rem;
          font-weight: 700;
          color: var(--primary-color);
          margin-bottom: 20px;
        }

        .why-choose-us-content p {
          font-size: 1.2rem;
          max-width: 800px;
          margin-bottom: 40px;
          line-height: 1.8;
        }

        .why-list {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .why-item {
          display: flex;
          align-items: center;
          gap: 15px;
          background: rgba(255, 255, 255, 0.7);
          padding: 15px;
          border-radius: 10px;
          box-shadow: var(--shadow);
        }

        .why-icon {
          font-size: 2.5rem;
          color: var(--primary-color);
        }

        .why-text h3 {
          font-size: 1.6rem;
          font-weight: 600;
          color: var(--primary-color);
        }

        .why-text p {
          font-size: 1rem;
          color: var(--text-color);
        }
      `}</style>
    </section>
  );
};

export default WhyChooseUs;