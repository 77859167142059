import React from "react";

const AboutUs = () => {
  return (
    <section className="about-us-section">
      <div className="about-container">
        {/** 🔹 Bloque 1 */}
        <div className="about-content">
          <div className="about-text">
            <h2>Nos Apasiona Crear</h2>
            <p>
              En <strong>isaSoft</strong>, no solo creamos estrategias digitales, creamos experiencias. Nos apasiona transformar ideas en realidades, dar vida a negocios a través del marketing digital y el contenido creativo. 
              No nos conformamos con lo que ya está, siempre buscamos llevar las marcas más allá de sus límites.
            </p>
          </div>
          <div className="about-image">
            <img src="/images/create.png" alt="Creatividad en acción" />
          </div>
        </div>

        <hr className="divider" />

        {/** 🔹 Bloque 2 */}
        <div className="about-content">
          <div className="about-text">
            <h2>Hacemos que tu Negocio Resalte</h2>
            <p>
              Creemos que cada empresa tiene una historia única y nuestro trabajo es hacerla brillar. Nos esforzamos en diseñar estrategias personalizadas, alineadas con la esencia de cada negocio, 
              asegurando que su voz no solo se escuche, sino que se sienta en cada interacción con su audiencia.
            </p>
          </div>
          <div className="about-image">
            <img src="/images/churros.jpg" alt="Branding y Estrategia" />
          </div>
        </div>

        <hr className="divider" />

        {/** 🔹 Bloque 3 */}
        <div className="about-content">
          <div className="about-text">
            <h2>Más que Clientes, una Relación de Crecimiento</h2>
            <p>
              No solo trabajamos para nuestros clientes, trabajamos con ellos. La colaboración es nuestra mayor fortaleza. Creemos en una relación de retroalimentación constante, donde cada idea suma,
              cada opinión cuenta y cada proyecto es una oportunidad para innovar. Juntos, probamos nuevas estrategias, exploramos nuevos caminos y creamos algo que realmente marque la diferencia.
            </p>
          </div>
          <div className="about-image">
            <img src="/images/ideas.png" alt="Trabajo en equipo" />
          </div>
        </div>

        <hr className="divider" />

        {/** 🔹 Bloque 4 */}
        <div className="about-content">
          <div className="about-text">
            <h2>Sin Fronteras, Sin Límites</h2>
            <p>
              No importa en qué parte del mundo estés, nuestra creatividad y pasión por lo que hacemos trascienden cualquier frontera. Conectamos con clientes de distintas industrias y culturas,
              adaptándonos a sus necesidades y brindando soluciones innovadoras.
            </p>
          </div>
          <div className="about-image">
            <img src="/images/globe.png" alt="Alcance Global" />
          </div>
        </div>
      </div>

      <style jsx>{`
        .about-us-section {
          background: #004d66;
          padding: 80px 20px;
          text-align: center;
        }

        .about-container {
          max-width: 1200px;
          margin: auto;
          padding: 20px;
          background: rgba(255, 255, 255, 0.95);
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        .about-content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          gap: 20px;
          margin-bottom: 40px;
        }

        .about-text {
          flex: 1 1 45%;
          text-align: left;
          padding: 20px;
        }

        .about-image {
          flex: 1 1 45%;
          display: flex;
          justify-content: center;
        }

        .about-image img {
          max-width: 100%;
          height: 250px;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        .divider {
          width: 80%;
          margin: 40px auto;
          border: none;
          border-top: 1px solid #ccc;
        }

        h2 {
          font-size: 2rem;
          font-weight: 700;
          color: var(--primary-color);
          margin-bottom: 15px;
        }

        p {
          font-size: 1.1rem;
          color: #333;
          line-height: 1.6;
        }

        /* 📌 Responsividad */
        @media (max-width: 768px) {
          .about-content {
            flex-direction: column;
            text-align: center;
          }

          .about-text {
            order: 1; /* El texto siempre va primero */
            flex: 1 1 100%;
            text-align: center;
          }

          .about-image {
            order: 2; /* La imagen va después */
            flex: 1 1 100%;
          }

          .about-image img {
            height: 200px;
          }
        }
      `}</style>
    </section>
  );
};

export default AboutUs;
