import React from "react";

const TermsAndConditions = () => {
  return (
    <section className="terms-section">
      <div className="terms-container">
        <h2>Términos y Condiciones del Servicio</h2>
        <p>Última actualización: [Fecha]</p>
        
        <h3>1. Alcance de los Servicios</h3>
        <p>
          isaSoft ofrece servicios de marketing digital, incluyendo gestión de redes sociales, asistentes virtuales, diseño gráfico, desarrollo web y software. Los detalles específicos de cada servicio se establecerán en un acuerdo previo con el cliente.
        </p>
        
        <h3>2. Modificaciones a los Servicios</h3>
        <p>
          isaSoft se reserva el derecho de modificar o suspender cualquier servicio con un aviso previo de 30 días. Los cambios en tarifas o términos serán notificados por escrito.
        </p>
        
        <h3>3. Pagos y Facturación</h3>
        <p>
          Los pagos deben realizarse por adelantado y dentro de un plazo máximo de 10 días naturales tras la emisión de la factura. Si el pago se retrasa más de 10 días, los servicios serán suspendidos y no podrán reactivarse sin el pago total del adeudo, más un cargo por pago tardío del 5%.
        </p>
        <p>
          Los pagos pueden realizarse en MXN para clientes en México (incluyendo IVA) y en USD para clientes internacionales. Métodos aceptados: Transferencias Bancarias SPEI/SWIFT, PayPal, TDD/TDC y pagos automáticos preautorizados.
        </p>
        
        <h3>4. Uso de los Servicios</h3>
        <p>
          El uso de nuestros servicios está limitado a propósitos legales y comerciales. Queda estrictamente prohibido el uso de los servicios para actividades ilegales. isaSoft no se hace responsable de cualquier uso indebido por parte del cliente.
        </p>
        
        <h3>5. Cobertura y Soporte</h3>
        <p>
          Nuestros servicios tienen cobertura global, limitándonos a la comunicación en Español, Inglés y Francés.
        </p>
        
        <h3>6. Cancelaciones y Reembolsos</h3>
        <p>
          Si el cliente desea cancelar su servicio, deberá notificarlo con al menos 15 días de anticipación al próximo ciclo de facturación. No se otorgarán reembolsos por pagos realizados.
        </p>
        
        <h3>7. Aceptación de los Términos</h3>
        <p>
          Al utilizar nuestros servicios, usted acepta estos términos y condiciones. isaSoft se reserva el derecho de actualizar estos términos en cualquier momento.
        </p>
      </div>
      <style jsx>{`
        .terms-section {
          background: #004d66;
          padding: 80px 20px;
          text-align: left;
          color: #333;
        }

        .terms-container {
          max-width: 1000px;
          margin: auto;
          background: #fff;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        h2 {
          font-size: 2.5rem;
          font-weight: 700;
          color: var(--primary-color);
          margin-bottom: 20px;
        }

        h3 {
          font-size: 1.8rem;
          font-weight: 600;
          color: var(--secondary-color);
          margin-top: 30px;
        }

        p {
          font-size: 1.2rem;
          line-height: 1.8;
          margin-bottom: 20px;
        }
      `}</style>
    </section>
  );
};

export default TermsAndConditions;