import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiMenu, FiX } from "react-icons/fi";
import { FaGlobeAmericas } from "react-icons/fa";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav className={`isa-navbar ${isScrolled ? "isa-navbar-scrolled" : ""}`}>
      <div className="isa-navbar-container">
        <Link to="/" className="isa-navbar-logo">
          <img
            src={isScrolled ? "/images/islogodark.png" : "/images/islogowhite.png"}
            alt="Logo"
            className="isa-navbar-logo-img"
          />
        </Link>
        
        <div className="isa-navbar-links-container">
          <div className="isa-navbar-links">
            <Link to="/" className="isa-navbar-link">{t("home")}</Link>
            <Link to="/about" className="isa-navbar-link">{t("about")}</Link>
            <Link to="/services" className="isa-navbar-link">{t("services")}</Link>
            <Link to="/portfolio" className="isa-navbar-link">{t("portfolio")}</Link>
            <Link to="/blog" className="isa-navbar-link">{t("blog")}</Link>
          </div>
        </div>

        <button 
          className="isa-navbar-menu-button" 
          onClick={() => setMenuOpen(!menuOpen)}
          style={{ color: isScrolled ? "#007bff" : "#fff" }}
        >
          {menuOpen ? <FiX size={28} /> : <FiMenu size={28} />}
        </button>
      </div>

      {menuOpen && (
        <div ref={menuRef} className="isa-navbar-dropdown">
          <Link to="/" className="isa-navbar-dropdown-link" onClick={() => setMenuOpen(false)}>{t("home")}</Link>
          <hr />
          <Link to="/about" className="isa-navbar-dropdown-link" onClick={() => setMenuOpen(false)}>{t("about")}</Link>
          <hr />
          <Link to="/services" className="isa-navbar-dropdown-link" onClick={() => setMenuOpen(false)}>{t("services")}</Link>
          <hr />
          <Link to="/portfolio" className="isa-navbar-dropdown-link" onClick={() => setMenuOpen(false)}>{t("portfolio")}</Link>
          <hr />
          <Link to="/blog" className="isa-navbar-dropdown-link" onClick={() => setMenuOpen(false)}>{t("blog")}</Link>
        </div>
      )}

      <style jsx>{`
        .isa-navbar {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          padding: 15px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: ${isScrolled ? "rgba(255, 255, 255, 0.9)" : "transparent"};
          box-shadow: ${isScrolled ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none"};
          z-index: 1000;
          transition: background-color 0.3s ease, box-shadow 0.3s ease;
        }

        .isa-navbar-container {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        }

        .isa-navbar-links {
          display: flex;
          gap: 20px;
        }

        .isa-navbar-menu-button {
          display: none;
          background: none;
          border: none;
          cursor: pointer;
        }

       .isa-navbar-dropdown {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 300px; /* Limita el ancho en pantallas grandes */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10px;
}

        .isa-navbar-dropdown-link {
          padding: 10px;
          text-align: center;
          color: #333;
          text-decoration: none;
          font-weight: 600;
        }

        .isa-navbar-dropdown-link:hover {
          background: #f1f1f1;
        }

        @media (max-width: 1024px) {
          .isa-navbar-links {
            display: none;
          }

          .isa-navbar-menu-button {
            display: block;
          }
        }

        
         
      `}</style>
    </nav>
  );
};

export default NavBar;
