import React from "react";
import { FaFacebookF, FaInstagram, FaTiktok, FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-columns">
          {/* Información de la empresa */}
          <div className="footer-brand">
            <h3>isaSoft</h3>
            <p>Impulsamos marcas a través del marketing digital, la tecnología y la innovación.</p>
            <p><FaMapMarkerAlt /> Puebla, México</p>
          </div>

          {/* Servicios */}
          <div className="footer-links">
            <h4>Servicios</h4>
            <ul>
              <li>Desarrollo Web</li>
              <li>Marketing Digital</li>
              <li>Branding</li>
              <li>CRM y Ventas</li>
            </ul>
          </div>

          {/* Contacto */}
          <div className="footer-contact">
            <h4>Contacto</h4>
            <div className="contact-container">
              <p><FaPhoneAlt /> <a href="tel:+5212381013593">+52 238 101 3593</a></p>
              <p><FaEnvelope /> <a href="mailto:info@isasoft.com">info@isasoft.com</a></p>
            </div>
          </div>

          {/* Redes Sociales */}
          <div className="footer-social">
            <h4>Síguenos</h4>
            <div className="social-icons">
              <a href="https://www.facebook.com/isasoftmx" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com/isasoftmx/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <FaInstagram />
              </a>
              <a href="https://www.tiktok.com/@isasoftmx" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
                <FaTiktok />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="footer-copy">&copy; {new Date().getFullYear()} isaSoft. Todos los derechos reservados.</p>
      <style jsx>{`
        .footer-section {
          background: #0d1117;
          color: #f0f0f0;
          padding: 50px 20px;
          text-align: center;
        }

        .footer-container {
          max-width: 1200px;
          margin: auto;
        }

        .footer-columns {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 20px;
          text-align: left;
        }

        .footer-brand h3 {
          font-size: 1.8rem;
          color: #58a6ff;
        }

        .footer-brand p {
          color: #FFF;
          max-width: 50%;
        }

        .footer-links, .footer-contact, .footer-social {
          flex: 1;
          min-width: 250px;
        }

        .footer-links h4, .footer-contact h4, .footer-social h4 {
          font-size: 1.2rem;
          margin-bottom: 10px;
        }

        .footer-links ul {
          list-style: none;
          padding: 0;
        }

        .footer-links ul li {
          margin-bottom: 5px;
          font-size: 1rem;
          opacity: 0.9;
        }

        .footer-contact p {
          font-size: 1rem;
          margin: 5px 0;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .footer-contact a {
          color: #58a6ff;
          text-decoration: none;
        }

        .social-icons {
          display: flex;
          gap: 15px;
        }

        .social-icons a {
          color: #58a6ff;
          font-size: 1.5rem;
          transition: transform 0.3s ease-in-out, color 0.3s ease;
        }

        .social-icons a:hover {
          transform: scale(1.2);
          color: #f0f0f0;
        }

        .footer-copy {
          margin-top: 30px;
          font-size: 0.9rem;
          opacity: 0.6;
        }

        /* Responsividad */
        @media (max-width: 768px) {
          .footer-columns {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }

          .footer-brand p {
            max-width: 100%;
          }

          .footer-links, .footer-contact, .footer-social {
            text-align: center;
            min-width: 100%;
          }

          .footer-contact p {
          text-align: center;
          }

          .contact-container{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          }

          .social-icons {
            justify-content: center;
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;
