import React from "react";
import { FaTools } from "react-icons/fa";

const Blog = () => {
  return (
    <section className="blog-section">
      <div className="blog-container">
        <FaTools className="blog-icon" />
        <h2>Blog en Construcción</h2>
        <p>Estamos trabajando en contenido increíble para ti. ¡Vuelve pronto!</p>
      </div>

      <style jsx>{`
        .blog-section {
          background: var(--primary-color);
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #fff;
        }

        .blog-container {
          max-width: 600px;
          padding: 20px;
        }

        .blog-icon {
          font-size: 5rem;
          margin-bottom: 20px;
          color: var(--accent-color);
        }

        h2 {
          font-size: 2.5rem;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.2rem;
        }
      `}</style>
    </section>
  );
};

export default Blog;