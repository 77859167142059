import React from "react";
import { useTranslation } from "react-i18next";

const ServicesSection = () => {
  const { t } = useTranslation();

  const services = [
    {
      title: "CRM",
      description: "Implementamos y optimizamos sistemas de CRM para mejorar la relación con clientes y maximizar ventas.",
      icon: "📊"
    },
    {
      title: "Optimización de Procesos de Ventas B2C & B2B",
      description: "Diseñamos estrategias efectivas para optimizar los procesos comerciales en entornos B2C y B2B.",
      icon: "📈"
    },
    {
      title: "Capacitación de Fuerza de Ventas",
      description: "Entrenamos equipos comerciales para mejorar sus habilidades en ventas y negociación.",
      icon: "🎤"
    },
    {
      title: "Diseño y Desarrollo Web",
      description: "Creamos plataformas digitales a medida para potenciar la presencia en línea de tu negocio.",
      icon: "🌐"
    },
    {
      title: "Creación de Contenido",
      description: "Generamos contenido atractivo y relevante para fortalecer tu estrategia de marketing digital.",
      icon: "✍️"
    },
    {
      title: "Sitios Web",
      description: "Creamos sitios web modernos, responsivos y optimizados para tu negocio.",
      icon: "💻"
    },
    {
      title: "Marketing Digital",
      description: "Estrategias de marketing en redes sociales, SEO y publicidad digital.",
      icon: "📢"
    },
    {
      title: "Consultoría IT",
      description: "Asesoramos en tecnología y digitalización de procesos empresariales.",
      icon: "🛠️"
    },
    {
      title: "Branding y Diseño",
      description: "Creamos identidades visuales únicas para fortalecer tu marca.",
      icon: "🎨"
    }
  ];
  return (
    <section className="services-section">
      <div className="services-header">
        <h2>Servicios</h2>
        <p>Contamos con amplia experiencia para mejorar tu marca.</p>
      </div>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      <style jsx>{`
        .services-section {
          background: linear-gradient(135deg, #004d66, #005f73);
          padding: 80px 20px;
          color: var(--text-light);
          text-align: center;
          position: relative;
          overflow: hidden;
        }

        .services-header h2 {
          font-size: 2.8rem;
          font-weight: 700;
          color: var(--accent-color);
          margin-bottom: 20px;
        }

        .services-header p {
        color: #FFF;
          font-size: 1.2rem;
          max-width: 800px;
          margin: 0 auto 40px;
          line-height: 1.8;
        }

        .services-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 30px;
          max-width: 1200px;
          margin: auto;
          position: relative;
        }

        .service-card {
          background-color: rgba(255, 255, 255, 0.1);
          padding: 30px;
          border-radius: 10px;
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          backdrop-filter: blur(10px);
          position: relative;
          overflow: hidden;
          box-shadow: var(--shadow);
        }

        .service-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 20px rgba(255, 255, 255, 0.2);
        }

        .service-icon {
          font-size: 3rem;
          color: var(--accent-color);
          margin-bottom: 20px;
        }

        .service-card h3 {
          font-size: 1.8rem;
          font-weight: 600;
          color: var(--text-light);
          margin-bottom: 10px;
        }

        .service-card p {
          font-size: 1rem;
          line-height: 1.6;
          color: #f1f1f1;
        }

        /* Decorative Patterns */
        .services-section::before {
          content: "";
          position: absolute;
          top: -50px;
          left: 0;
          width: 100%;
          height: 200px;
          background: url("/images/wave-top.svg");
          background-size: cover;
        }

        .services-section::after {
          content: "";
          position: absolute;
          bottom: -50px;
          left: 0;
          width: 100%;
          height: 200px;
          background: url("/images/wave-bottom.svg");
          background-size: cover;
        }
      `}</style>
    </section>
  );
};

export default ServicesSection;
